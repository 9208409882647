import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function TabsBellow() {
  const [iconPills, setIconPills] = React.useState("1");
  return (
    <>
      <div className="section section-tabs" id="customer-section" >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="12">
            <span className="category">Kutak za potrošače</span>
              <Card>
                <CardHeader>
                  <Nav className="justify-content-center" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={iconPills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("1");
                        }}
                      >
                        Kupovina
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("2");
                        }}
                      >
                        
                        Ljekovitost
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("3");
                        }}
                      >
                        Mlijeko
                      </NavLink>
                    </NavItem>
                   
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    className="text-left"
                    activeTab={"iconPills" + iconPills}
                  >
                    <TabPane tabId="iconPills1">
                      <span >
                      Prilikom kupovine mlijeka uvijek se rukovodite datumom upotrebe na pakiranju.<br></br><br></br>
Mirisom na vrhu ambalaže i promjenom njenog oblika, možete ocijeniti da li je mlijeko promijenilo svoja svojstva (miris, boju, okus i sl). To se događa ukoliko je neko vrijeme bilo pohranjeno izvan rashladnog uređaja.<br></br><br></br>
Mlijeko uvijek čuvajte u hladnjaku jer se na višim temperaturama može ukiseliti. Ujedno ga dobro zatvarajte kako ne bi upilo arome ostalih namirnica u hladnjaku. Izbjegavajte stavljanje mlijeka u vrata hladnjaka jer ga izlažete promjenama temperature prilikom svakog otvaranja i zatvaranja hladnjaka, a time i mogućem kvarenju.
                      </span>
                    </TabPane>
                    <TabPane tabId="iconPills2">
                      <span>
                      Novo iz svijeta znanosti...
                      <li>Redovni unos mlijeka smanjuje rizik od osteoporoze, povišenog krvnog tlaka i raka debelog crijeva,</li>
                      <li>Mlijeko neutralizira kiselinu u usnoj šupljini i tako štiti od karijesa,</li>
                      <li>Nedavno provedena epidemiološka studija pokazala je da redovitim pijenjem mlijeka smanjujemo rizik od nastanka bubrežnih kamenaca…</li>

                      </span>
                    </TabPane>
                    <TabPane tabId="iconPills3">
                      <span style={{paddingLeft:"20px"}}>
                      
Kravlje mlijeko u prosjeku ima sljedeći hemijski sastav:
                      <li style={{width:"30%"}}>Voda <h6 className="float-right">87,40%</h6></li>
                      <li style={{width:"30%"}}>Suha materija: <h6 className="float-right">12,60%</h6></li>
                      <li style={{width:"30%"}}>Voda <h6 className="float-right">87,40%</h6></li>

                      
Od toga:
                        <li style={{width:"30%"}}>Laktoza (mliječni šečer):<h6 className="float-right">4,70% </h6></li>
                        <li style={{width:"30%"}}>Masnoća:<h6 className="float-right">3,90%</h6 ></li>
                        <li style={{width:"30%"}}>Bjelančevine: <h6 className="float-right">3,30%</h6> </li>
                        <li style={{width:"30%"}}>Minerali: <h6 className="float-right">0,70%</h6></li>
         
                      </span>
                    </TabPane>
                    
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TabsBellow;
