import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Tabs() {
  const [pills, setPills] = React.useState("3");
  return (
    <>
      <div className="section section-tabs" id="about-section" >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="12">
              <span className="category">O nama</span>
              <Card>
                <CardHeader>
                  <Nav
                    className="nav-tabs-neutral justify-content-center"
                    data-background-color="blue"
                    role="tablist"
                    tabs
                  >
                    <NavItem>
                      <NavLink
                        className={pills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        O nama
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        Historija
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("3");
                        }}
                      >
                        Misija
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "4" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("4");
                        }}
                      >
                        Vizija
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "5" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("5");
                        }}
                      >
                        Strategija
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    className="text-left"
                    activeTab={"pills" + pills}
                  >
                    <TabPane tabId="pills1">
                      <span>
“MIALGROS” d.o.o. je bosansko-hercegovačko društvo koje je smješteno u Gornjem Vakufu – Uskoplju, u centralnoj Bosni. Osnovna djelatnost društva “MILAGROS” je proizvodnja i prerada mlijeka i mliječnih proizvoda, te punjenje mineralne vode. <br></br><br></br>
Mliječni proizvodni program, po kojem je “MILAGROS” poznat, su: svježe pasterizovano “Vrbasko mlijeko”, tečni “Vrbaski jogurt”, “Vrbaske pavlake”, “Vranički sir za pitu”, “Vrbaski polutvrdi sir - GAUDA” i “Vrbaski masni sir”. Proizvodnja je bazirana isključivo na sirovinama koje su domaćeg porijekla i namijenjeni su krajnjoj potrošnji na domaćem tržištu. <br></br><br></br>
Na Gradačačkom međunarodnom sajmu poljoprivrede i prehrambene industrije, 2006 godine, “MILAGROS” je za mliječne proizvode dobio dva priznanja za kvalitet: 
<li>“VRBASKI JOGURT 3,2% m.m.” je osvojio srebrenu medalju za kvalitet,</li> 
<li>a sir pod nazivom “VRBASKA GAUDA” je dobitnik bronzane medalje. </li>
<br></br><br></br>
Tražeći tehničku vodu, koja bi se koristila u procesu proizvodnje i prerade mlijeka i mliječnih proizvoda, inžinjeri “MILAGROS”, u samoj blizini tvornice, pronašli su čistu mineralnu vodu. Tokom 2006. godine Društvo je pokrenulo proces punjenja pronađene mineralne vode, koja se crpi pumpom iz bunara dubokog 62 metra. <br></br><br></br>
Od tada je mineralna voda, koja je dobila naziv “BISTRICA”, vrlo važan produkt Društva. Voda “BISTRICA” je prepoznata po svojoj čistoći, bistrini, ljekovitim i osvježavajućim svojstvima. <br></br><br></br>
Prirodna voda “BISTRICA” puni se u ambalaži od 5 litara, litar i pol, pola litre, te 0,33 litra, a minaralna voda pakovana je u ambalažama od litar i pol, pola litre i 0,33 litra. <br></br><br></br>
Na renomiranom svjetskom sajmu mineralnih voda, koji je održan u Brkeley Springsu u Virđiniji, 2005.godine mineralna voda “BISTRICA” je osvojila bronzanu medalju za kvalitetu.<br></br><br></br>
Na gradačačkom Međunarodnom sajmu poljoprivrede i prehrambene industrije, 2006 godine, prirodna izvorska gazirana voda “BISTRICA” je osvojila srebrenu medalju.<br></br><br></br>
2007. godine „MILAGROS“ d.o.o. je uspostavilo integrirani sistem upravljanja kvalitetom i sigurnošću hrane, prema standardu BAS EN ISO 9001:2000 Sistem upravljanja kvalitetom – Zahtjevi i BAS EN ISO 22000:2000, u cilju postizanja zadovoljstva svojih kupaca i sigurnosti potrošača. <br></br><br></br>
Sistem upravljanja kvalitetom i zdravstvene sigurnosti hrane primjenjuje se na nabavu, proizvodnju, pakiranje, skladištenje, prodaju i transport mliječnih prerađevina i vode, a uslovljava potrebu primjene svih zahtjeva sistema upravljanja kvalitetom i zdravstvenom sigurnošću utvrđenih normom ISO 22000:2005, Requirements for a HACCP based Food System i ISO 9001:2000. <br></br><br></br>
Osnovni ciljevi Društva su uspostavljanje integriranog sistema upravljanja kvalitetom i sigurnošču hrane, kako bi bila konkurentna na domaćem trži. 
Težnja Društva se bazira na proizvodnji eko-proizvoda.<br></br><br></br>
Izrada gotovih proizvoda se odvija po vlastitim recepturama i tehnološkim rješenjima preuzetih od proizvođača opreme na bazi kojih je omogućeno potpuno udovoljavanje zahtjevima kupca u pogledu kvalitete proizvoda i sigurnosti hrane.  <br></br><br></br>
Posebna pažnja se pridaje kvalitetu i ukusu proizvoda čime se kroz budući rad želi ostvariti prepoznatljiva višegodišnja tradicija u preradi mlijeka. Rad i poštenje su osnovne vrijednosti koje se podstiču u poslovnoj etici kompanije.<br></br><br></br>

Za svoje potrebe “MILAGROS” trenutno veže mnoge farme i male proizvođače mlijeka, sa kojima ima ugovore o otkupu sirovina. Proizvođači mlijeka, koji surađuju sa MILAGROSOM, moraju posjedovati zdravstvene kartone, kao garant za svako stočno grlo, kako bi bili otklonjeni rizici u proizvodnji i prenošenju zaraznih bolesti, te osigurala proizvodnja zdrave hrane.<br></br><br></br>

                      </span>
                    </TabPane>
                    <TabPane tabId="pills2">
                      <span>
                      “AGROCENTAR” d.o.o. je osnovano 30.09.1996. godine. Prvobitna djelatnost kojom se Društvo bavilo je bila trgovina na malo poljoprivrednog repromaterijala, otkup bilja i šumskih plodova.<br></br><br></br>

1999. godine Društvo je počelo sa programom otkupa i prerade mlijeka što je bio i početak rada mljekare.<br></br><br></br>

U septembru 2000-te mljekara “AGROCENTAR” startuje sa zvaničnom proizvodnjom mlijeka i mliječnih proizvoda, te u tom periodu upošljava 5 radnika. Instalirani kapacitet je bio 1000 litara mlijeka dnevno sa proizvodnim programom, kojeg su činili: 

  <li>Tečni jogurt,</li>
  <li>Slatko mlijeko i</li>
  <li>Čvrsti jogurt.</li>
<br></br>

2003. godine proširuje se kapacitet i osavremjenjuje tehnološki proces proizvodnje, te se uvodi novi mliječni proizvodni program: 
<li>Pavlaka i</li> 
<li>Bijeli salamurni sir.</li>	<br></br>
Instalisani kapacitet u to vrijema raste na 10 000 litara mlijeka dnevno.<br></br><br></br>

2004. godine se uvodi novi mliječni program i počinje se sa proizvodnjom sira tipa Gauda (polutvrdi sir).<br></br><br></br>

2005. godine u sklopu Društva počinje sa radom i punionica vode. Proizvodni program se proširuje i jača tržišna pozicija. 
Od 2005 god. organizaciono i tržišno Društvo se konsolidira tako što dobiva znatno jaču poziciju na domaćem tržištu. 
Društvo “AGROCENTAR” 2005. godine proširuje svoj proizvodni pogon za još jednu halu i nabavlja novu opremu za punjenje gazirane i izvorske vode, te tako proširuje i svoj proizvodni asortiman. 
<br></br><br></br>
Početkom 2006 godine Društvo nabavlja opremu za izradu vlastite ambalaže. 
<br></br><br></br>
U toku 2007 godine uvodi se HACCAP standard i vrše se pripreme za certificiranje proizvoda po ISO 9001:2000.
<br></br><br></br>
2008 godine uspostavljen je i integrisan sistem upravljanja kvalitetom i sigurnošću hrane, prema standardu BAS EN ISO 9001:2000 Sistem upravljanja kvalitetom – Zahtjevi i BAS EN ISO 22000:2000, u cilju postizanja zadovoljstva svojih kupaca i sigurnosti potrošača.
Sistem upravljanja kvalitetom i zdravstvene sigurnosti hrane primjenjuje se na nabavu, proizvodnju, pakiranje, skladištenje, prodaju i transport mliječnih prerađevina i vode, a uslovljava potrebu primjene svih zahtjeva sistema upravljanja kvalitetom i zdravstvenom sigurnošću utvrđenih normom ISO 22000:2005, Requirements for a HACCP based Food System i ISO 9001:2000. 
U toku 2007 i 2008 godine dolazi do jačanje tržišne pozicije Društva. Uspostavljena je suradnja sa renomiranim trgovačkim kućama u Bosni i Hercegovini. 
U oblasti proizvodnje su izvršene pripreme za proizvodnju kajmaka, voćnih jogurta, mliječnih i sirnih namaza, kao i drugih pakovanja tečnog jogurta (tečni jogurt u PVC kesi).
Početkom 2009 godine, kupovinom nove mašine za punjenje i pakovanje tečnog jogurta i mlačanice, postojeća oprema je dodatno osavremenjena.
<br></br><br></br>
Krajem 2015. godine društvo “MILAGROS” d.o.o. pod svoje okrilje preuzima sve djeltanosti i procese koje je vršio “AGROCENTAR” i time stavlja sve proizvodnju i prodaju istih proizvoda pod svojim imenom.
<br></br><br></br>
Danas “MILAGROS” d.o.o. svojim potrošačima nudi 26 vrsta proizvoda iz 2 proizvodne linije: 17 mliječnih proizvoda i 9 različitih vrsta punjenja prirodne izvorske gazirane i neagzirane vode.

                     
                      </span>
                    </TabPane>
                    <TabPane tabId="pills3">
                      <span>
                      Mi postojimo da proizvodimo i prodajemo zdravstveno ispravne mliječne proizvode i vodu iz čiste prirode, bez dodatka konzervanasa, da bi udovoljili životnim potrebama ljudi.
                      <br></br><br></br>
<p className="text-success">Racionalno korištenje energije i vode, smanjenje nastajanja otpada kao i odvojeno sakupljanje otpada za recikliranje, obaveza je svih zaposlenika.
Kontinuirano pratimo i usklađujemo se sa postojećim i budućim propisima iz oblasti hrane.</p>

                      </span>
                    </TabPane>
                    <TabPane tabId="pills4">
                      <span>
                      Osnovu vizije društva čine:
                      <li>Uspostava Društva i resursa koji će osigurati razvoj društva na osnovama dugoročnih predviđanja potreba tržišta – kupaca i krajnjih potrošača, </li>
                      <li>Osiguranje kontinuiteta u unapređivanju imidža uspješne i pouzdane kompanije,</li>
                      <li>Dostizanje visokog nivoa kvaliteta proizvoda / pružanja usluga i kvaliteta u svim segmentima poslovanja.</li>

<br></br>
U dugoročnom predviđanju Društvo vidi svoj razvoj u proizvodnji specifičnih sirnih proizvoda i proizvoda probiotičkog svojstva, kako bi iskoristili sve pozitivne prirodne resurse na lokalnom nivou.

                      </span>
                    </TabPane>
                    <TabPane tabId="pills5">
                      <span>
                      Naša strategija se zasniva na:
                      <li>Praćenju razvoja mikrobnih kultura i osavremenjavanju tehnološke opreme za proizvodnju,</li>
                      <li>Afirmaciji organske proizvodnje,</li>
                      <li>Poboljšanju organizacije u firmi i jačanju odgovornosti zaposlenih,</li>
                      <li>Stalnom praćenju i analiziranju tržista i konkurencije,</li>
                      <li>Nastojanju da se bude ispred konkurencije,</li>
                      <li>Standardizaciji kvaliteta proizvoda i procesa uz neprekidna poboljšanja,</li>
                      <li>Stalnom monitoringu i osiguranju zdravstvene ispravnosti naših proizvoda u cjelokupnom prehrambenom lancu – od farme do stola.</li>
<br></br>
Kontinuirano se provodi doškolovavanje osoblja u radnim prostorijama i izvan firme, sa ciljem podizanja kvalitete u izvršenju njihovih zadataka uključujući i zdravstvenu sigurnost proizvoda.

                      </span>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Tabs;
