import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SignUp() {
  return (
    <>
      <div id="location"
        className="section section-signup"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg11.jpg").default + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "700px",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="6">
              <Card className="" data-background-color="blue">
               
                <CardHeader className="text-center">
                  <CardTitle className="title-up" tag="h3">
                    Naša lokacija
                  </CardTitle>
                  
                </CardHeader>
                <CardBody>
                  
                  <div className="align-center ">
                    <iframe className="width-100-percent" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d642.1007378559071!2d17.523671488110406!3d43.97533936379907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475f0b872eeba187%3A0xb0ff5658457f26e5!2sMilagros%20d.o.o.!5e1!3m2!1shr!2sba!4v1623594165505!5m2!1shr!2sba"  style={{ border: "0" }} allowFullScreen="" title="location" loading="lazy"></iframe>
                  </div>
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    className="btn-neutral btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Posjetite nas
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col className="ml-auto mr-auto" md="10" xl="6">
              <Card className="" data-background-color="blue">
                <CardHeader className="text-center">
                  <CardTitle className="title-up" tag="h3">
                    Kontakt
                  </CardTitle>
                 
                </CardHeader>
                <CardBody>
                  <span>Adresa: <h6>Pajić Polje bb, Gornji Vakuf –Uskoplje 70240 </h6></span>
                  <span>Email: <h6>info@milagros.ba</h6></span>
                  <span><h6>agrocentar.gv@bih.net.ba</h6></span>
                  <span>Tel/Fax: <h6>+387 30 286 600 </h6></span>
                  <span>ID broj: <h6>4236624420002</h6></span>
                </CardBody>
              </Card>
            </Col>

          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default SignUp;
