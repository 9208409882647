import React from "react";



// core components

function Navbars() {
  return (
    <>
      <div className="section section-navbars">
        
        <div id="navbar">
          <div
            className="navigation-example"
            style={{
              backgroundImage:
                "url(" + require("assets/img/bg7.jpg").default + ")",
            }}
          >
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbars;
